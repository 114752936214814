import { TinyColor } from '@ctrl/tinycolor';
import { initComponentToken } from '../../input/style/token';
import { getArrowToken } from '../../style/roundedArrow';
export const initPickerPanelToken = (token) => {
    const { componentCls, controlHeightLG, paddingXXS, padding } = token;
    return {
        pickerCellCls: `${componentCls}-cell`,
        pickerCellInnerCls: `${componentCls}-cell-inner`,
        pickerYearMonthCellWidth: token.calc(controlHeightLG).mul(1.5).equal(),
        pickerQuarterPanelContentHeight: token.calc(controlHeightLG).mul(1.4).equal(),
        pickerCellPaddingVertical: token.calc(paddingXXS).add(token.calc(paddingXXS).div(2)).equal(),
        pickerCellBorderGap: 2, // Magic for gap between cells
        pickerControlIconSize: 7,
        pickerControlIconMargin: 4,
        pickerControlIconBorderWidth: 1.5,
        pickerDatePanelPaddingHorizontal: token
            .calc(padding)
            .add(token.calc(paddingXXS).div(2))
            .equal(), // 18 in normal
    };
};
export const initPanelComponentToken = (token) => ({
    cellHoverBg: token.controlItemBgHover,
    cellActiveWithRangeBg: token.controlItemBgActive,
    cellHoverWithRangeBg: new TinyColor(token.colorPrimary).lighten(35).toHexString(),
    cellRangeBorderColor: new TinyColor(token.colorPrimary).lighten(20).toHexString(),
    cellBgDisabled: token.colorBgContainerDisabled,
    timeColumnWidth: token.controlHeightLG * 1.4,
    timeColumnHeight: 28 * 8,
    timeCellHeight: 28,
    cellWidth: token.controlHeightSM * 1.5,
    cellHeight: token.controlHeightSM,
    textHeight: token.controlHeightLG,
    withoutTimeCellHeight: token.controlHeightLG * 1.65,
});
export const prepareComponentToken = (token) => (Object.assign(Object.assign(Object.assign(Object.assign({}, initComponentToken(token)), initPanelComponentToken(token)), getArrowToken(token)), { presetsWidth: 120, presetsMaxWidth: 200, zIndexPopup: token.zIndexPopupBase + 50 }));
